<template>
    <div>
        <div style="padding-top: 2300px; z-index: 101;" 
            data-1200="position:relative; opacity:1"
            data-2450="opacity:1"
            data-2500="opacity:0">

            <div class="col-12">
                <h5 style="text-align: center; color:gold;">Akad Nikah</h5>
                <p style="text-align: center;">
                    <b>{{ this.textDate }}<br>
                        Pukul 09.00 s.d 10.00 <br>
                        Ascot Sudirman Jakarta
                    </b><br>
                    <small style="font-family: 'system-ui';">Kecamatan Setiabudi, Kota Jakarta Selatan</small>
                </p>
                <div style="margin-bottom: 20px;">
                    <a target="_blank" :href="this.maps" rel="noopener" class="btn btn-primary" style="pointer-events: all; cursor: pointer;">
                        <i class="fa fa-map-marker pe-1"></i> Open Maps
                    </a>
                </div>
            </div>
            <div class="col-12">
                <h5 style="text-align: center; color:gold;">Resepsi</h5>
                <p style="text-align: center;">
                    <b>{{ this.textDate }}<br>
                        Pukul 11.30 s.d 15.00 <br>
                        {{ this.locationEvent }} 
                    </b><br>
                    <small style="font-family: 'system-ui';">Kecamatan Setiabudi, Kota Jakarta Selatan</small>
                </p>
            </div>
            <div class="col-12" style="margin-top:20px;">
                <span class="before">
                    <div class="col-md-12">
                        <button type="button" v-on:click="addCalendar" class="btn btn-info text-white me-1">
                            <i class="fa fa-calendar pe-1"></i> Add to Calendar
                        </button>
                        <a target="_blank" :href="this.maps" rel="noopener" class="btn btn-primary" style="pointer-events: all; cursor: pointer;">
                            <i class="fa fa-map-marker pe-1"></i> Open Maps
                        </a>
                    </div>
                </span>
                <span class="after" style="display:none;">
                    <span class="day">Akad Nikah</span><br>
                    <span class="time">{{ this.textDate }}</span><br><br><br>
                    <div class="submit-button text-center">
                        <i class="fa fa-heart fa-2x"></i>
                    </div>
                    <div class="wow bounceIn text-after" style="padding-top: 50px; padding-bottom: 40px;">
                        <span>Alhamdulillah SAH</span>
                    </div>
                </span>

                <ul class="countdown">
                    <p class="footer">ready for the remaining time</p>
                    <li>
                        <span class="days">00</span>
                        <p class="days_ref">days</p>
                    </li>
                    <li class="seperator">.</li>
                    <li>
                        <span class="hours">00</span>
                        <p class="hours_ref">hours</p>
                    </li>
                    <li class="seperator">:</li>
                    <li>
                        <span class="minutes">00</span>
                        <p class="minutes_ref">minutes</p>
                    </li>
                    <li class="seperator">:</li>
                    <li>
                        <span class="seconds">00</span>
                        <p class="seconds_ref">seconds</p>
                    </li>
                </ul>
                <p class="closing-event" data-wow-delay="1s" style="margin:8px; padding-top:-300px;">
                    Merupakan suatu kebahagiaan bagi kami atas kehadiran serta doa restu dari Bapak/Ibu/Saudara/i
                </p>
            </div>
        </div>
        
        <div style="z-index:-1; background: white" 
            data-0="z-index:-1; position:fixed; bottom:0; opacity:0" 
            data-300="opacity:1"
            data-900="opacity:0" 
            data-2000="opacity:1"
            data-2800="opacity:0">
            <img src="img/page2.png" style="min-height: 100vh; width: 100%; max-width: 600px;">
        </div>
        
        <div class="col-12" style="padding:10px;"
            data-2500="display:block; position:fixed; bottom:40vh; left:0%; opacity:0" 
            data-2700="opacity:1"
            data-3000="opacity:0;"
            data-3200="display:none;">
            <p>
                “Dan diantara tanda-tanda kekuasaan-Nya ialah diciptakan-nya pasangan hidup dari jenismu sendiri supaya kamu mendapatkan ketenangan hati, 
                dan dijadikan-Nya Kasih Sayang diantara kamu, <br>Sesungguhnya yang demikian itu menjadi tanda-tanda kebesaran-Nya bagi orang-orang yang berfikir.”
            </p>
            <span style="color:gold;">
                <b>QS. Ar-Rum : 21</b>
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Event',
        methods: {
            addCalendar: function() {
                let url = "https://calendar.google.com/calendar/render?"+
                            "action=TEMPLATE"+
                            "&text="+this.textEvent+
                            "&dates="+this.dateEvent+
                            "&details="+this.urlEvent+
                            "&location="+this.locationEvent+
                            "&sf=true&output=xml";
                window.open(url, '_blank', 'noopener')
            }
        },
        mounted() {
            $('.countdown').downCount({
                date: this.eventDate,
                offset: +10
            }, function () {
                $('.countdown').hide();
                $('.before').hide();
                $('.after').show();
                $('.closing-event').text('“Baarakallahu laka wa baarakaa alaika wa jamaa bainakumaa fii khoir”');
            });
        },
    }
</script>
