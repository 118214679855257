<template>
	<div>
        <!-- Loader -->
        <div id="preloader">
            <div class="preloader pulse">
            <i class="fa fa fa-heart" aria-hidden="true"></i>
            </div>
        </div>
        
        <!-- Modal -->
        <div class="modal fade" id="modalOpening" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="modalOpeningTitle" aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-body text-center justify-content-center">
                        <h2 class="pt-4">{{ urlParam ? urlParam : 'Hi,' }}</h2>
                        <b>You're invited <br/> The Wedding Celebration of</b>
                        <p class="pt-2">
                            <strong style="font-size:40px;">{{ this.coupleName }}</strong>
                        </p>
                        <div class="pt-2 pb-4">
                            <button type="button" class="btn btn-warning" data-bs-target="#modalCovid" data-bs-toggle="modal" data-bs-dismiss="modal">
                                Open Invitation
                            </button>
                        </div>
                        <p style="font-family: 'system-ui'; font-size:11px;">
                            <br>
                            photo by : <br>
                            instagram.com/nanangsupriyadi_photography
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="modalCovid" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="modalCovidTitle" aria-hidden="true" data-bs-dismiss="modal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="background:black;">
                    <div class="modal-body text-center justify-content-center">
                        <div class="pt-2 pb-4">
                            <img src="/img/info2.png" class="img-fluid">
                        </div>
                        <p style="font-family: 'system-ui'; font-size:12px;">
                            Acara ini menerapkan protokol kesehatan pencegahan Covid-19.<br> 
                            Di mohon untuk mematuhi protokol kesehatan selama berada di area acara pernikahan <br>
                            <code class="pt-1" style="float:right;">close in {{ timer }}</code>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- Audio -->
        <audio id="myAudio">
            <source src="/sounds/bensound-acousticbreeze.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
        <button type="button" v-on:click="playSound" value="0" class="btn btn-circle btn-sound" title="Play Audio">
            <i class="icon-sound fa fa-play"></i>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'Preloader',
        data() {
            return {
                timer: 0
            }
        },
        props: {
            urlParam: String
        },
        methods: {
            playSound: function() {
                var val = $('.btn-sound').val() == 1 ? 'true' : 'false';
                var src = document.getElementById("myAudio"); 
                $('#modalOpening').modal('hide');
                if (val == 'true') {
                    $('.btn-sound').val('0');
                    $('.icon-sound').removeClass('fa-pause');
                    $('.icon-sound').addClass('fa-play');
                    src.pause();
                }
                else {
                    $('.btn-sound').val('1');
                    $('.icon-sound').removeClass('fa-play');
                    $('.icon-sound').addClass('fa-pause');
                    src.play();
                }
            },
            openFullscreen: function() {
                var elem = document.documentElement;
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.webkitRequestFullscreen) { /* Safari */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) { /* IE11 */
                    elem.msRequestFullscreen();
                }
            }
        },
        created() {
            $(window).on('load', function() {
                $('body').css('overflow','hidden');
                $('.preloader').fadeOut(); 
                $('#preloader').delay(550).fadeOut('slow'); 
                $('#modalOpening').modal('show'); 
            });
        },
        mounted() {
            var self = this;
            
            var modalOpening = document.getElementById('modalOpening')
            const body = document.querySelector("body")
            modalOpening.addEventListener('show.bs.modal', function() {
                body.style.overflow = "visible";
            })
            
            var modalCovid = document.getElementById('modalCovid')
            modalCovid.addEventListener('show.bs.modal', function() {
                self.openFullscreen()
                self.timer = 3
                setTimeout(function() {
                    let modal = bootstrap.Modal.getInstance(modalCovid)
                    modal.hide()
                }, 4000);
            })

            modalCovid.addEventListener('hidden.bs.modal', function() {
                self.playSound()
            })
        },
        watch: {
            timer: {
                handler(value) {
                    if (value > 0) {
                        setTimeout(() => {
                            this.timer--;
                        }, 1500);
                    }
                },
                immediate: true // This ensures the watcher is triggered upon creation
            }
        }
    }
</script>

