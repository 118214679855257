<template>
	<div>
        <div 
            data-1="position:fixed; bottom:0; opacity:1" 
            data-200="opacity:0">
            <img src="img/page1.png" style="min-height: 100vh; width: 100%; max-width: 600px;">
        </div>

        <div class="row">
            <div
                data-1="position: fixed; top: 40%; left: 0%; opacity:1;" 
                data-100="top: 0%; opacity:0;">

                <h3>{{ this.coupleName }}</h3>
                <h1 style="color:gold;"><b>Wedding Invitation</b></h1>
                <p class="p-4">
                    ~ save the date ~ <br>
                    <b>{{ this.textDate }}</b>
                </p>
                <div style="position: fixed; bottom: 3vh; left: 41vw;">
                    <span style="font-family: 'system-ui'; font-size: 11px;">swipe up slowly</span>
                </div>
                <div class="mouse_scroll">
                    <div>
                        <span class="m_scroll_arrows trei"></span>
                        <span class="m_scroll_arrows doi"></span>
                        <span class="m_scroll_arrows unu"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div 
                data-0="position: fixed; top: 35%; left: 100%; opacity:1; display:block;" 
                data-300="left: 0%;"
                data-800="opacity:0;"
                data-900="display:none;">
                <p>
                    <b style="color:gold;">Bismillahirrahmaanirrahim</b> <br>
                    Assalamu’alaikum Warahmatullahi Wabarakatuh <br><br>
                    Dengan memohon rahmat dan ridho Allah SWT dan tanpa mengurangi rasa hormat, 
                    izinkan kami mengundang Bapak/Ibu, Saudara/i dan rekan-rekan untuk hadir dan memberikan doa restu pada acara pernikahan kami, <br>
                </p>
            </div>
        </div>

        <div class="row" data-1000="opacity:1; display:block;" data-1900="opacity:1" data-2000="opacity:0; display:none;">
            <div class="col-12">
                <div
                    data-500="position:fixed;bottom:0%; top:70%; opacity:0" 
                    data-1000="bottom:25%; top:25%; opacity:1"
                    data-1200="top:0%; opacity:1">
                    <img src="photos/couple/1.png" style="width: 100%; height: auto; max-width: 600px;">
                </div>
                <div
                    data-500="position:fixed;top:0%; bottom:100%; opacity:0" 
                    data-1000="top:25%; bottom:25%; opacity:1"
                    data-1200="top:0%; opacity:1">
                    <img src="photos/couple/2.png" style="width: 100%; height: auto; max-width: 600px;">
                </div>
            </div>
        </div>

        <div class="row">
            <div
                data-500="position: fixed; top: 50%; left: 0%; opacity:0; display:block;" 
                data-1000="opacity:0"
                data-1150="opacity:1"
                data-1300="top: 50%;"
                data-1900="top: 0%; z-index: -9999; display:none;">
                
                <h3 style="color:gold;"><b>Akang Calon Suami, S.Kom.</b></h3>
                <small style="font-size: 13px; font-family: 'system-ui';">Putra pertama dari<br>Bapak Fulan & Ibu Fulanah</small>
                <p style="padding-top:15px;">&</p>
                <h3 style="color:gold;"><b>Teteh Calon Istri, S.Pd.</b></h3>
                <small style="font-size: 13px; font-family: 'system-ui';">Putri ke 3 dari<br>Bapak Fulan & Ibu Fulanah</small>
            </div>
        </div>
	</div>
</template>

<script>
    import '@/styles/mouse-scroll.scss'
    export default {
        name: 'Home'
    }
</script>

