<template>
    <div>
        <div style="z-index:-1;"
            data-8500="bottom:0; opacity:0" 
            data-9100="opacity:1"
            data-9200="-webkit-mask: linear-gradient(bottom, #fff, transparent); mask:linear-gradient(bottom, #fff, transparent);">
            <img src="img/page1.png" style="min-height: 100vh; width: 100%; max-width: 600px;">
        </div>
        <div class="text-center justify-content-center" 
            style="height:100vh; padding-top:45vh;">

            <div class="col-12 text-center">
                <p style="padding:10px;">
                    {{ this.coupleName }} <br>
                    <small>&copy; 2022 by <b onClick="window.open('https://ondangan.website', '_blank', 'noopener')">ondangan.website</b></small>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Footer',
        mounted() {
            window.addEventListener('scroll', function() {
                var element = document.querySelector('#recaptcha');
                var position = element.getBoundingClientRect();

                // checking whether fully visible
                // if (position.top >= 0 && position.bottom <= window.innerHeight) {
                //     console.log(element.scrollHeight);
                // }
            });
        }
    }
</script>
