<template>
    <div class="row text-center justify-content-center">
        
        <div class="col-12"
            data-8200="position:fixed; top:35vh; left:0%; opacity:0" 
            data-8300="opacity:1"
            data-8400="top:10vh"
            data-9100="opacity:0"
            data-9200="top:0vh">
            <p style="font-size:25px;font-weight:bold;padding-top:25px;padding-bottom:10px;margin:0;">
                Wedding Gift
            </p>
            <p style="padding:10px;">
                Doa restu Anda merupakan karunia yang sangat berarti bagi kami. Dan jika memberi adalah ungkapan tanda kasih Anda, Anda dapat memberi kado secara cashless.
            </p>
        </div>
        <div class="giftcard"
            style="z-index: 101;"
                data-8300="display:block; top:90vh; opacity:0;" 
                data-8600="top:40vh; opacity:1;"
                data-8800="opacity:0;"
                data-9100="display:none; top:0vh">
            <div class="mb-5">
                <img src="/img/logo-bni.png" alt="logo-bni" width="120px;">
                <p class="pt-2">
                    <span id="copy1">9927262652</span>
                    <br>a.n Akang Calon Suami
                </p>
                <button type="button" class="btn btn-warning" @click="copyText('copy1')">
                    Copy Number
                </button>
            </div>
            <div class="">
                <img src="/img/logo-bca.png" alt="logo-bca" width="120px;">
                <p class="pt-2">
                    <span id="copy2">68543332</span>
                    <br>a.n Teteh Calon Istri
                </p>
                <button type="button" class="btn btn-warning" @click="copyText('copy2')">
                    Copy Number
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'Gift',
        methods: {
            copyText(id) {
                var copyText = document.getElementById(id);  
                var input = document.createElement("textarea");
                input.value = copyText.textContent;
                document.body.appendChild(input);
                input.select();
                document.execCommand("Copy");
                input.remove();
                this.$toast.default('Copied', {
                    position: "bottom",
                    dismissible: true
                })
            }
        }
    }
</script>
