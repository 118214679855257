<template>
    <div id="skrollr-body">
        <Preloader :urlParam="this.urlParam" />
        <Home/>
        <Event/>
        <Story/>
        <Gallery/>
        <Gift/>
        <Contact :urlParam="this.urlParam"/>
        <Footer/>
    </div>
</template>

<script>
    import Preloader from '@/components/Preloader.vue'
    import Home from '@/components/Home.vue'
    import Event from '@/components/Event.vue'
    import Story from '@/components/Story.vue'
    import Gallery from '@/components/Gallery.vue'
    import Gift from '@/components/Gift.vue'
    import Contact from '@/components/Contact.vue'
    import Footer from '@/components/Footer.vue'

    export default {
        name: 'Index',
        data() {
            return {
                urlParam: ''
            }
        },
        components: {
            Preloader,
            Home,
            Event,
            Story,
            Gallery,
            Gift,
            Contact,
            Footer
        },
        created() {
            let url = new URL(location.href).searchParams.get('to')
            this.urlParam = url
        },
        mounted() {
            skrollr.init().destroy();
            skrollr.init({
                forceHeight: false,
                smoothScrollingDuration: 500,
                mobileCheck: function(){
                    if ((/Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera)){
                        // mobile device
                    }
                }
            });
        }
    }
</script>