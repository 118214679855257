<template>
	<div>
        <div style="margin-top: 1300px;" 
            data-8700="position:relative; opacity:1;">
            <div class="col-12" style="background:#000;"
                data-8700="position:relative"
                data-8800="position:sticky; top:0;">
                <p style="font-size:25px;font-weight:bold;padding-top:25px;padding-bottom:20px;margin:0;">Message for Us</p>
            </div>
            
            <div class="contact">
				<div>
				<!-- <div class="nicescroll-box" style="height: 70vh; overflow-y: scroll"> -->
					<div class="card" style="background:#000;">
						<ul class="list-group list-group-flush">
							<li class="list-group-item" style="padding-top:15px; background:#000; color:#fff;" v-for="row in messages" :key="row.key">
								<vue-letter-avatar :name=row.name :rounded=true size="40" />
								<div style="padding-left:60px;margin-top:-49px;line-height:20px;">
									<b>{{ row.name }}</b>&nbsp; <br>
									<span class="badge" :class=" row.present == 1 ? 'bg-primary' : 'bg-danger'">{{ row.present == 1 ? 'Hadir' : 'Berhalangan hadir' }}</span><br>										
								</div>
								<div style="padding-left:60px;padding-top:8px;line-height:20px">
									<i>"{{ row.message }}"</i>
								</div>
								<span class="pull-right">
									<small>{{ row.created_at ? format_date(row.created_at.toMillis()) : 'a few seconds ago' }}</small>
								</span>
							</li>

							<!-- <li class="list-group-item" style="padding-top:15px;" v-for="index in 10" :key="index">
								<vue-letter-avatar name="Ricky" :rounded=true size="40" />
								<div style="padding-left:60px;margin-top:-49px;line-height:20px;">
									<b>Ricky</b>&nbsp; <br>
									<span class="badge bg-primary">Hadir</span><br>										
								</div>
								<div style="padding-left:60px;padding-top:8px;line-height:20px">
									<i>"Lorem ipsum"</i>
								</div>
								<span class="pull-right">
									<small>a few seconds ago</small>
								</span>
							</li> -->

						</ul>
					</div>
				</div>
			</div>
        </div>
		<br>
		<div class="contact-form" style="z-index:102;">
			<form @submit.prevent="sendMessage">
				<div class="row">
					<div class="col-md-12 mb-3">
						<div class="form-group">
							<input 
								v-model="name"
								type="text" 
								class="form-control"
								style="background:#fff;" 
								id="name" 
								name="name" 
								placeholder="Your Name">
							<div class="help-block with-errors" style="color:red;" v-if="is_required_name">{{ is_required_name }}</div>
						</div>
					</div>
					<div class="col-md-12 mb-3">
						<div class="form-group">
							<select
								v-model="present"
								class="form-control" 
								style="background:#fff;"
								id="present"
								name="present"> 
								<option selected value="" style="color:gray">Ready to attend?</option>
								<option value="1">Berkenan hadir</option>
								<option value="0">Maaf tidak bisa hadir</option>
							</select>
							<div class="help-block with-errors" style="color:red;" v-if="is_required_present">{{ is_required_present }}</div>
						</div>
					</div>
					<div class="col-md-12">
						<div class="form-group mb-3"> 
							<textarea 
								name="text_msg"
								ref="text_msg"
								v-model="text_msg"
								v-on:keydown="clear"
								v-on:keyup.ctrl.enter="sendMessage"
								class="form-control"
								style="background:#fff;"
								placeholder="Write your message"
								data-role="textarea">
							</textarea>
							<div class="help-block with-errors" style="color:red;" v-if="is_required_msg">{{ is_required_msg }}</div>
						</div>
						<div class="form-group mb-3" id="recaptcha">
							<vue-recaptcha ref="recaptcha"
								@verify="onVerify" sitekey="6LeZYTMUAAAAAG_rsgpvNvSSiy1DhMU_0iM_VNRP">
							</vue-recaptcha>
							<div class="help-block with-errors" style="color:red;" v-if="is_required_captcha">{{ is_required_captcha }}</div>
						</div>
						<div class="submit-button text-center">
							<button class="btn btn-success text-white" type="submit">Send Message</button>
							<div class="clearfix"></div> 
						</div>
					</div>
				</div>
			</form>
		</div>
    </div>
</template>

<script>
import { firestore, datetime } from '@/config/Firebase'
import VueRecaptcha from 'vue-recaptcha'
import moment from 'moment'

export default {
    name: 'Contact',
	data() {
		return {
			robot: false,
			name: '',
			present: '',
			text_msg: '',
			is_required_name: '',
			is_required_present: '',
			is_required_msg: '',
			is_required_captcha: '',
			messages: [],
		}
	},
	props: {
		urlParam: String
	},
	components: { VueRecaptcha },
	methods: {
		alert() {
			this.$toast.default('Successfully Sent', {
				position: "top-right",
				dismissible: true
			})
		},
		format_date(value){
			if (value) {
				// return moment(String(value)).format('YYYY-MM-DD')
				return moment.unix(value/1000).format("DD MMM YYYY hh:mm:ss")
			}
		},
		onVerify(response) {
			if (response) this.robot = true;
		},
		sendMessage() {
			if (!this.name) {
				this.is_required_name = 'Please enter your name'
			}
			if (!this.text_msg) {
				this.is_required_msg = 'Write your message'
			}
			if (!this.present) {
				this.is_required_present = 'Please choose one'
			}
			if (!this.robot) {
				this.is_required_captcha = 'Recaptcha is required'
			}

			if (this.robot && this.name && this.present && this.text_msg) {
				// Save
				firestore.collection('ondangan-demo-messages').add({
					name: this.name,
					present: this.present,
					message: this.text_msg,
					created_at: datetime,
				})
				this.name = ''
				this.present = ''
				this.text_msg = ''
				this.alert()
				this.clear()
				grecaptcha.reset();
			}
		},
		messageListener() {
			let self = this
			firestore.collection("ondangan-demo-messages").orderBy("created_at", "asc")
			.onSnapshot(function(snapshot) {
				snapshot.docChanges().forEach(function(change) {
					if (change.type === "added") {
						self.messages.push(change.doc.data());
					}
				});
			});
		},
		clear() {
			this.is_required_name = ''
			this.is_required_present = ''
			this.is_required_msg = ''
			this.is_required_captcha = ''
		},
	},
	mounted() {
		this.name = this.urlParam
		this.messageListener()
	},
}
</script>