<template>
	<div>
        <div style="margin-top: 3400px; z-index: 102;" 
            data-4700="position:relative; opacity:1;">
            <div class="col-12" style="background:#000;"
                data-4700="position:relative"
                data-6100="position:sticky; top:0;">
                <p style="font-size:25px;font-weight:bold;padding-top:25px;padding-bottom:20px;margin:0;">Gallery</p>
            </div>
            <img class="img-fluid" src="photos/gallery/thumb (2).jpg">
            <img class="img-fluid" src="photos/gallery/thumb (1).jpg">
            <img class="img-fluid" src="photos/gallery/thumb (3).jpg">
            <img class="img-fluid" src="photos/gallery/thumb (4).jpg">
            <iframe width="100%" height="400" :src="this.youtube" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Gallery'
    }
</script>