<template>
	<div>
        <div style="z-index:-1;"
            data-2000="bottom:0; opacity:0" 
            data-2500="opacity:1"
            data-3000="-webkit-mask: linear-gradient(bottom, #000, transparent); mask:linear-gradient(bottom, #000, transparent);"
            data-end="opacity:0;">
            <img src="img/background.png" style="min-height: 100vh; width: 100%; max-width: 600px;">
        </div>

        <div class="col-12" style="max-width:600px;"
            data-3000="top:-100%; display:none; background:none;" 
            data-3010="top:30%; display:block;" 
            data-3200="top:45%;" 
            data-3400="top:3%;" 
            data-6100="display:block;" 
            data-7200="display:none;">
            <p style="font-size:25px;font-weight:bold;">Our Story</p>
            
            <div class="progress verticalrotate justify-content-end"
                data-2700="opacity:0;"
                data-2800="opacity:1;">
                <div 
                    ref="progress"
                    class="progress-bar" 
                    role="progressbar" 
                    data-3400="width:0%;"
                    data-5200="width:100%;">
                {{ this.progress }}</div>
            </div>
        </div>
        
        <div class="col-12" style="text-align:left;">
            <section style="z-index: -1; margin-top:-1px;"
                data-3200="bottom:0%; top:100vh; display:block;" 
                data-3600="top:9vh;"
                data-6100="top:9vh;"
                data-6500="display:none;">
                <p>- First meet</p>
                <code>12 Oktober 2010</code>
                <div class="story-text">
                    Kita bertemu di kantin sekolah saat masih duduk di bangku SMA
                </div>
                <img class="img-story" src="/photos/gallery/thumb (1).jpg">
            </section>
            <section style="z-index: -1;"
                data-3600="bottom:0%; top:100vh; display:block;" 
                data-4000="top:17vh;"
                data-6100="top:17vh;"
                data-6500="display:none;">
                <p>- First date</p>
                <code>26 November 2019</code>
                <div class="story-text">
                    Karena kebetulan satu kota, dan udah 7 tahun sejak lulus SMA, Romeo megajak bertemu untuk nostalgia
                </div>
                <img class="img-story" src="/photos/gallery/thumb (2).jpg">
            </section>
            <section style="z-index: -1;"
                data-4000="bottom:0%; top:100vh; display:block;" 
                data-4400="top:25vh;"
                data-6100="top:25vh;"
                data-6500="display:none;">
                <p>- Proposal</p>
                <code>11 Januari 2020</code>
                <div class="story-text">
                    Cerita di mulai darisini
                </div>
                <img class="img-story" src="/photos/gallery/thumb (3).jpg">
            </section>
            <section style="z-index: -1;"
                data-4400="bottom:0%; top:100vh; display:block;" 
                data-4800="top:33vh;"
                data-6100="top:33vh;"
                data-6500="display:none;">
                <p>- Twist and turn</p>
                <code>8 Mei 2020 - 17 Agustus 2020</code>
                <div class="story-text">
                    Sempat terjadi putus hubungan antara kami berdua
                </div>
                <img class="img-story" src="/photos/gallery/thumb (4).jpg">
            </section>
            <section style="z-index: -1;"
                data-4800="bottom:0%; top:100vh; display:block;" 
                data-5200="top:41vh;"
                data-6100="top:41vh;"
                data-6200="display:none;">
                <p>- Engagement</p>
                <code>28 April 2021</code>
                <div class="story-text">
                    Romeo, teman SMA ku datang melamar!
                </div>
                <img class="img-story" src="/photos/gallery/thumb (5).jpg">
            </section>
        </div>
            
    </div>
</template>

<script>
    export default {
        name: 'Story',
         data() {
            return {
                progress: 0
            }
        },
        created () {
            window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll() {
                let percent = this.$refs.progress.style.width;
                percent = percent.slice(0, -1);
                this.progress = Math.floor(percent)+' %';
            }
        }
    }
</script>